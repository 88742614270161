import React from "react";
import { useNavigate } from "react-router-dom";

// Icons
import { BsCheck2Circle } from "react-icons/bs";

import "./codeSuccess.css";

export default function CodeSuccess({
  closeSuccessModal,
  generatedCode,
  updateTable,
}) {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    closeSuccessModal();
    updateTable();
    // navigate("/dashboard/empleados");
  };

  return (
    <div className="modal-code-success">
      <div className="header-modal">
        <p>Agregar nuevo usuario</p>
        <span className="btn-close" onClick={handleSubmit}></span>
      </div>
      <BsCheck2Circle className="check-icon-modal" />
      <p className="modal-title-success">¡Usuario registrado!</p>
      <span className="modal-subtitle">
        Utiliza este código al iniciar sesión para acceder a tu cuenta
      </span>
      <div className="container-code">
        <p className="code">{generatedCode}</p>
      </div>
      <button type="submit" className="btn-success" onClick={handleSubmit}>
        Finalizar
      </button>
    </div>
  );
}
