import React from "react";

import { doc, deleteDoc } from "firebase/firestore";
import { dbDashboard } from "../../../services/firebase";

// Styles
import "./deleteUser.css";

export default function DeleteUser({
  closeDeleteUserModal,
  user,
  updateUsers,
}) {
  const deleteUser = async () => {
    try {
      const userDocRef = doc(dbDashboard, "users", user.id);
      await deleteDoc(userDocRef);
      updateUsers();
      closeDeleteUserModal();
    } catch (error) {
      // Manejar errores en la eliminación
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div className="modal-code-success">
      <div className="header-modal">
        <p>Eliminar usuario</p>
        <span className="btn-close" onClick={closeDeleteUserModal}>
          X
        </span>
      </div>

      <p className="modal-title">
        ¿Estás seguro de que quieres eliminar el usuario {user.name}?
      </p>
      <span className="modal-subtitle">
        Una vez eliminado no se podrá recuperar la información
      </span>

      <div className="container-buttons">
        <button className="btn-cancel" onClick={closeDeleteUserModal}>
          Cancelar
        </button>
        <button className="btn-delete" onClick={deleteUser}>
          Eliminar
        </button>
      </div>
    </div>
  );
}
