import React from "react";

// Styles
import "./addEmployee.css";

export default function AddEmployee({ openAddUserModal }) {
  return (
    <>
      <button className="btn-add-employee" onClick={openAddUserModal}>
        + Agregar usuario
      </button>
    </>
  );
}
