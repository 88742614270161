import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

// Conección a la dbDashboard
import { dbDashboard } from "../../../services/firebase";

// Componentes
import InfoCode from "../infoCode/infoCode";
import EditUser from "../editUser/editUser";
import DeleteUser from "../deleteUser/deleteUser";

// Iconos
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

// Estilos
import "./tableEmployees.css";

export default function TableEmployees({
  searchUser,
  filterType,
  updateTable,
}) {
  const [showInfoCodeModal, setShowInfoCodeModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserForDeletion, setSelectedUserForDeletion] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const navigate = useNavigate();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(7);

  const openInfoCodeModal = (user) => {
    setSelectedUser(user);
    setShowInfoCodeModal(true);
  };

  const closeInfoCodeModal = () => {
    setShowInfoCodeModal(false);
  };

  const openDeleteUserModal = (user) => {
    setSelectedUserForDeletion(user);
    setShowDeleteUserModal(true);
  };

  const closeDeleteUserModal = () => {
    setShowDeleteUserModal(false);
  };

  const openEditUserForm = (user) => {
    setSelectedUser(user);
    setShowEditUserForm(true);
  };

  const closeEditUserForm = () => {
    setShowEditUserForm(false);
  };

  // Referenciamos a la dbDashboard
  const userCollection = collection(dbDashboard, "users");

  // Función para obtener los datos de la dbDashboard
  const getUsers = async () => {
    const dataUsers = await getDocs(userCollection);

    setUsers(dataUsers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getUsers();
  }, [updateTable]);

  const updateUsers = async () => {
    try {
      const dataUsers = await getDocs(userCollection);
      setUsers(dataUsers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error("Error updating users: ", error);
    }
  };

  // Get current users
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users
    .filter((user) =>
      user.name.toLowerCase().includes(searchUser.toLowerCase())
    )
    .filter((user) => (filterType ? user[filterType] !== undefined : true))
    .sort((a, b) => {
      if (filterType === "training_status") {
        if (sortDirection === "asc") {
          return a[filterType].localeCompare(b[filterType]);
        } else {
          return b[filterType].localeCompare(a[filterType]);
        }
      }
    })
    .slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <table className="table-dashboard-employees">
        <thead className="table-title">
          <tr>
            <th>Nombre completo</th>
            <th>Cargo</th>
            <th>Departamento</th>
            <th>Estado de capacitación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id} className="table-item">
              <td
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/dashboard/empleados/${user.id}`)}
              >
                {user.name}
              </td>
              <td>{user.rol}</td>
              <td>{user.department}</td>
              <td>
                <div
                  className={`status-circle status-${user.training_status
                    .toLowerCase()
                    .replace(/\s/g, "")}`}
                />
                {user.training_status}
              </td>

              <td className="container-icons">
                <AiOutlineInfoCircle
                  className="icon-table"
                  onClick={() => openInfoCodeModal(user)}
                />
                <FiEdit2
                  className="icon-table"
                  onClick={() => openEditUserForm(user)}
                />
                <RiDeleteBin6Line
                  className="icon-table"
                  onClick={() => openDeleteUserModal(user)}
                />
              </td>
            </tr>
          ))}
        </tbody>

        {showInfoCodeModal && (
          <div className="overlay">
            <InfoCode
              closeInfoCodeModal={closeInfoCodeModal}
              user={selectedUser}
            />
          </div>
        )}

        {showEditUserForm && (
          <div className="overlay">
            <EditUser
              closeEditUserForm={closeEditUserForm}
              user={selectedUser}
              updateUsers={updateUsers}
            />
          </div>
        )}

        {showDeleteUserModal && (
          <div className="overlay">
            <DeleteUser
              closeDeleteUserModal={closeDeleteUserModal}
              user={selectedUserForDeletion}
              updateUsers={updateUsers}
            />
          </div>
        )}
      </table>

      {users.length > usersPerPage && (
        <Pagination
          usersPerPage={usersPerPage}
          totalUsers={users.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

function Pagination({ usersPerPage, totalUsers, paginate, currentPage }) {
  const totalPages = Math.ceil(totalUsers / usersPerPage);

  return (
    <div className="pagination">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="prev-page"
      >
        {"<"}
      </button>
      <span className="current-page">Página {currentPage}</span>
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="next-page"
      >
        {">"}
      </button>
    </div>
  );
}
