import React from "react";

import EmployeeDetails from "../components/organismos/employeeDetails/employeeDetails";

export default function EmployeePage() {
  return (
    <>
      <EmployeeDetails />
    </>
  );
}
