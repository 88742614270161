import React from "react";

// Components
import Faq from "../components/organismos/faq/faq";

export default function FAQPage() {
  return (
    <>
      <Faq />
    </>
  );
}
