import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { dbDashboard } from "../../../services/firebase";

import Chart from "chart.js/auto";
import TableEmployeeDetails from "../../moleculas/tableEmployeeDetails/tableEmployeeDetails";

import "./employeeDetails.css";

export default function EmployeeDetails() {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [trainingInfo, setTrainingInfo] = useState(null);
  const [activeTab, setActiveTab] = useState("Dashboard");

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUserDetails = async () => {
    try {
      const userDocRef = doc(dbDashboard, "users", userId);
      const userDetailsDoc = await getDoc(userDocRef);

      if (!userDetailsDoc.exists()) {
        // Manejar el caso en que el usuario no exista
        console.error("Usuario no encontrado");
        return;
      }

      setUserDetails(userDetailsDoc.data());

      // Obtener la información de la capacitación
      const trainingInfoCollectionRef = collection(
        dbDashboard,
        "users",
        userId,
        "trainingInformation"
      );
      const trainingInfoDocs = await getDocs(trainingInfoCollectionRef);

      const trainingData = trainingInfoDocs.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTrainingInfo(trainingData);
    } catch (error) {
      setErrorMessage("Error al obtener detalles del usuario.");
    } finally {
      setLoading(false);
    }
  };

  // Función que suma todos los datos de score
  const sumScore = () => {
    let totalScore = 0;
    for (let i = 0; i < trainingInfo.length; i++) {
      totalScore += trainingInfo[i].score;
    }
    return totalScore;
  };

  // Referencias para los elementos de la gráfica
  let columnChart;
  let lineChart;
  let pieChart;

  const createCharts = () => {
    if (loading) {
      // Evitar crear gráficas si los datos aún se están cargando
      return;
    }

    if (columnChart) {
      columnChart.destroy();
    }
    if (lineChart) {
      lineChart.destroy();
    }
    if (pieChart) {
      pieChart.destroy();
    }

    if (!trainingInfo || trainingInfo.length === 0) {
      setErrorMessage("No hay datos de capacitaciones disponibles.");
      return;
    }

    if (activeTab === "Dashboard") {
      // Obtener datos para las gráficas
      const labels = trainingInfo.map(
        (info, index) => `Capacitación ${index + 1}`
      );
      const successesData = trainingInfo.map(
        (info) => info.number_of_successes
      );
      const errorsData = trainingInfo.map((info) => info.number_of_errors);
      const durationData = trainingInfo.map(
        (info) => info.duration_of_training
      );

      // Obtén los datos de errores más comunes para la primera semana
      const commonErrorsData = [
        trainingInfo[0]["armado_de_la_pieza"],
        trainingInfo[0]["interpretación_del_plano"],
        trainingInfo[0]["velocidad_de_corte"],
      ];

      // Gráfica de Columnas
      columnChart = new Chart(document.getElementById("columnChart"), {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Número de Aciertos",
              data: successesData,
              backgroundColor: "#5A6ACF",
            },
            {
              label: "Número de Errores",
              data: errorsData,
              backgroundColor: "#E6E8EC",
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
        },
      });

      // Gráfica de Líneas
      lineChart = new Chart(document.getElementById("lineChart"), {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Duración del Entrenamiento (minutos)",
              data: durationData,
              backgroundColor: "#5A6ACF",
              borderColor: "#5A6ACF",
              fill: false,
            },
          ],
        },
        options: {
          scales: {
            y: {
              suggestedMin: 20,
              suggestedMax: 60,
              stepSize: 5,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
        },
      });

      // Gráfico Circular
      pieChart = new Chart(document.getElementById("pieChart"), {
        type: "doughnut",
        data: {
          labels: [
            "Armado de la Pieza",
            "Interpretación del Plano",
            "Velocidad de Corte",
          ],
          datasets: [
            {
              labels: "Áreas de mejora más comunes",
              data: commonErrorsData,
              backgroundColor: ["#5A6ACF", "#8593ED", "#C7CEFF"],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
          },
        },
      });
    }

    // Limpiar el mensaje de error si no hay errores
    setErrorMessage(null);
  };

  // Manejar el click en las pestañas
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    createCharts();
  };

  useEffect(() => {
    getUserDetails();
  }, [userId]);

  useEffect(() => {
    if (trainingInfo) {
      createCharts();
    }
  }, [trainingInfo, activeTab, loading]);

  return (
    <div className="container-dashboard">
      {loading ? (
        <p>Cargando...</p>
      ) : errorMessage ? (
        <div className="no-data-message">
          <p>{errorMessage}</p>
        </div>
      ) : (
        <div>
          <div className="container-title-employee">
            <p className="title-employee">{userDetails.name}</p>
            <p className="status-employee">
              <div
                className={`status-circle status-${userDetails.training_status
                  .toLowerCase()
                  .replace(/\s/g, "")}`}
              />
              {userDetails.training_status}
            </p>
          </div>
          <div className="container-info-employee">
            <p>Departamento: {userDetails.department}</p>
            <p>Cargo: {userDetails.rol}</p>
          </div>
          <div className="tabs-container">
            <div
              className={`tab ${activeTab === "Dashboard" ? "active" : ""}`}
              onClick={() => handleTabClick("Dashboard")}
            >
              Dashboard
            </div>
            <div
              className={`tab ${
                activeTab === "Capacitaciones" ? "active" : ""
              }`}
              onClick={() => handleTabClick("Capacitaciones")}
            >
              Capacitaciones
            </div>
          </div>

          {activeTab === "Dashboard" && (
            <div className="container-dashboard-charts">
              <div className="chart a">
                <p className="score-dashboard">{sumScore()} Puntos</p>
                <canvas id="columnChart" width="100%" height="100%"></canvas>
              </div>
              <div className="chart b">
                <canvas id="lineChart" width="100%" height="100%"></canvas>
              </div>
              <div className="chart c">
                <canvas id="pieChart" width="100%" height="100%"></canvas>
              </div>
            </div>
          )}
          {activeTab === "Capacitaciones" && (
            <div>
              <TableEmployeeDetails
                training={trainingInfo}
                user={userDetails}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
