import React from "react";
import { Route, Routes } from "react-router-dom";

import Sidebar from "../components/moleculas/sidebar/sidebar";
import Navbar from "../components/moleculas/navbar/navbar";

import { HomeDashboard } from "../components/organismos/homeDashboard/homeDashboard";
import Employees from "../components/organismos/employees/employees";
import EmployeePage from "../pages/EmployeePage";
import FAQ from "../pages/FAQPage";
import EditClientPage from "../pages/EditClientPage";

import "../App.css";

export const AppRouter = () => {
  return (
    <div className="container-main">
      <Sidebar />
      <div className="container-section">
        <Navbar />
        <Routes>
          <Route path="/home" element={<HomeDashboard />} />
          <Route path="/empleados" element={<Employees />} />
          <Route path="/empleados/:userId" element={<EmployeePage />} />
          <Route path="/ayuda" element={<FAQ />} />
          <Route path="/editar-usuario" element={<EditClientPage />} />
        </Routes>
      </div>
    </div>
  );
};
