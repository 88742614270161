import React from "react";

import Profile from "../../atomos/profile/profile";

import "./navbar.css";

export default function Navbar() {
  return (
    <div className="navbar-dashboard">
      <Profile />
    </div>
  );
}
