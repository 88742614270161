import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { collection, addDoc } from "firebase/firestore";
import { dbDashboard } from "../../../services/firebase";

import "./newUser.css";

// Función para generar un código aleatorio
function generateRandomCode() {
  const groups = [];
  for (let i = 0; i < 3; i++) {
    const group = Math.random().toString(36).substr(2, 4).toUpperCase();
    groups.push(group);
  }
  return groups.join("-");
}

export default function NewUser({ closeForm, handleSubmitForm }) {
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [rol, setRol] = useState("");
  const [training_status, setTraining_status] = useState("Sin iniciar");

  const navigate = useNavigate();

  // Traemos la coleccion de usuarios
  const usersCollection = collection(dbDashboard, "users");

  // Funcion para agregar un nuevo usuario
  const handleSubmit = async (event) => {
    event.preventDefault();
    const code = generateRandomCode();
    await addDoc(usersCollection, {
      name: name,
      department: department,
      rol: rol,
      training_status: training_status,
      code: code,
    });
    navigate("/dashboard/empleados");
    handleSubmitForm(code);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-new-user">
        <div className="header-form">
          <p>Agregar nuevo usuario</p>
          <span className="btn-close" onClick={closeForm}></span>
        </div>
        <div className="container-input">
          <label>Nombre completo</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-form"
            placeholder="Escribe el nombre completo"
          />
        </div>
        <div className="input-form-group">
          <div className="input-department">
            <label>Departamento</label>
            <select
              className="input-form"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
            >
              <option value="0">Selecciona tu departamento</option>
              <option value="Desarrollo">Desarrollo</option>
              <option value="Ventas">Ventas</option>
              <option value="Marketing">Marketing</option>
            </select>
          </div>

          <div className="input-position">
            <label>Cargo</label>
            <select
              className="input-form"
              value={rol}
              onChange={(e) => setRol(e.target.value)}
            >
              <option value="0">Selecciona tu cargo</option>
              <option value="Frontend">Frontend</option>
              <option value="Backend">Backend</option>
              <option value="Fullstack">Fullstack</option>
            </select>
          </div>
        </div>

        <button type="submit" className="button-form">
          Registrar usuario
        </button>
      </form>
    </>
  );
}
