import "./App.css";
import { ContextProvider } from "./context";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./hooks/PrivateRoute";
import { AppRouter } from "./router/AppRouter";

import { HomePage } from "./pages/HomePage";
import { ServicePage } from "./pages/ServicePage";
import { ContactPage } from "./pages/ContactPage";
import { PortalPage } from "./pages/PortalPage";
import { ContactSupportPage } from "./pages/ContactSupportPage";

function App() {
  return (
    <ContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/servicio" element={<ServicePage />} />
          <Route path="/contacto" element={<ContactPage />} />
          <Route path="/portal" element={<PortalPage />} />
          <Route path="/contacto-soporte" element={<ContactSupportPage />} />
          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <AppRouter />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </ContextProvider>
  );
}

export default App;
