import React, { useState, useEffect } from "react";
import { auth } from "../services/firebase";
import { createContext, useContext } from "react";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

export const Context = createContext();

// Hook personalizado para acceder al contexto de autenticación
export const useAuth = () => {
  const context = useContext(Context);
  if (!context) throw new Error("There is no AuthProvider");
  return context;
};

// Proveedor de autenticación para la aplicación
export function ContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

  const logout = () => signOut(auth);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Context.Provider value={{ login, logout, user, loading }}>
      {children}
    </Context.Provider>
  );
}
