import React from "react";
import NavBar from "../components/organismos/navBar/navBar";
import NavBarMobile from "../components/organismos/navBarMobile/navBarMobile";
import Footer from "../components/organismos/footer/footer";
import Contacto from "../components/organismos/contacto/contacto";

export const ContactPage = () => {
  return (
    <div className="background-icaro">
      <NavBar />
      <NavBarMobile />
      <Contacto />
      <Footer />
    </div>
  );
};
