import React from "react";
import { useAuth } from "../context";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children, redirectPath = "/portal" }) {
  const { user, loading } = useAuth();

  if (loading)
    return (
      <h3
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Cargando...
      </h3>
    );

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
}
