import React from "react";
import { useParams } from "react-router-dom";

import { doc, deleteDoc } from "firebase/firestore";
import { dbDashboard } from "../../../services/firebase";

// Styles
import "./deleteTraining.css";

export default function DeleteTraining({
  closeDeleteTrainingModal,
  info,
  updateTraining,
}) {
  const { userId } = useParams();

  const deleteTraining = async () => {
    try {
      // Accede al documento específico en la colección "trainingInformation"
      const trainingInfoRef = doc(
        dbDashboard,
        "users",
        userId,
        "trainingInformation",
        info.id
      );

      // Elimina el documento
      await deleteDoc(trainingInfoRef);

      // Actualiza la lista de capacitaciones
      updateTraining();
      closeDeleteTrainingModal();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div className="modal-code-success">
      <div className="header-modal">
        <p>Eliminar capacitación</p>
        <span className="btn-close" onClick={closeDeleteTrainingModal}>
          X
        </span>
      </div>

      <p className="modal-title">
        ¿Estás seguro de que quieres eliminar la capacitación?
      </p>
      <span className="modal-subtitle">
        Una vez eliminado no se podrá recuperar la información
      </span>

      <div className="container-buttons">
        <button className="btn-cancel" onClick={closeDeleteTrainingModal}>
          Cancelar
        </button>
        <button className="btn-delete" onClick={deleteTraining}>
          Eliminar
        </button>
      </div>
    </div>
  );
}
