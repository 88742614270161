import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context";
import { useNavigate, NavLink } from "react-router-dom";

import styles from "./login.module.css";

const Login = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [mostrarAnuncio, setMostrarAnuncio] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await login(user.email, user.password);
      navigate("/dashboard/home");
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // Detectar si el usuario está en un dispositivo móvil
    const esDispositivoMovil =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // Actualizar el estado para mostrar el anuncio solo si es un dispositivo móvil
    setMostrarAnuncio(esDispositivoMovil);
  }, []); // El segundo parámetro del useEffect es un array de dependencias, vacío para ejecutarse solo una vez al montar el componente.

  return (
    <div className={styles.loginPage}>
      {mostrarAnuncio && (
        <div className={styles.overlay}>
          <div className={styles.mobileAlert}>
            <p>
              Recomendamos ver este contenido en dispositivos de escritorio.
            </p>
            <div className={styles.buttonLogin}>
              <NavLink to="/">Regresar</NavLink>
            </div>
          </div>
        </div>
      )}
      <div className={styles.containerLogin}>
        <h1 className={styles.title}>Iniciar sesión</h1>
        <p className={styles.subtitle}>
          Ingresa el correo y contraseña asignados por el administrador para
          acceder al sistema.
        </p>

        <form onSubmit={handleFormSubmit}>
          <div className={styles.inputContainer}>
            <label>Correo electrónico:</label>
            <input
              type="email"
              name="email"
              className={styles.inputLogin}
              placeholder="ejemplo@email.com"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className={styles.input}>
            <label>Contraseña:</label>
            <input
              type="password"
              name="password"
              className={styles.inputLogin}
              placeholder="********"
              onChange={handleInputChange}
              required
            />
          </div>

          {error && (
            <p className={styles.errorMsg}>Correo y/o Contraseña incorrectos</p>
          )}

          <button className={styles.buttonLogin}>Ingresar</button>
        </form>

        <span className={styles.helpTextLogin}>
          ¿Necesitas ayuda para ingresar tu código?{" "}
          <a href="/contacto-soporte">Contáctanos aquí.</a>
        </span>
      </div>
    </div>
  );
};

export default Login;
