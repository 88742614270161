import React, { useState } from "react";
import { collection, getDocs } from "firebase/firestore";

// Conección a la dbDashboard
import { dbDashboard } from "../../../services/firebase";

// Componentes
import Search from "../../atomos/search/search";
import TableEmployees from "../../moleculas/tableEmployees/tableEmployees";
import AddEmployee from "../../atomos/addEmployee/addEmployee";
import NewUser from "../../moleculas/newUser/newUser";
import CodeSuccess from "../../moleculas/codeSuccess/codeSuccess";

// Styles
import "./employees.css";

export default function Employees() {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [generatedCode, setGeneratedCode] = useState("");

  const [searchUser, setSearchUser] = useState("");
  const [filterType, setFilterType] = useState("");

  const openAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleSearch = (event) => {
    setSearchUser(event.target.value);
  };

  const handleFilterChange = (filter) => {
    setFilterType(filter);
  };

  // Referenciamos a la dbDashboard
  const userCollection = collection(dbDashboard, "users");

  const getUsersInTableEmployees = async () => {
    try {
      const dataUsers = await getDocs(userCollection);
      setUsers(dataUsers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      console.log("Usuarios actualizados con éxito");
    } catch (error) {
      console.error("Error al actualizar usuarios: ", error);
    }
  };

  const updateTable = async () => {
    await getUsersInTableEmployees();
  };

  const handleSubmitForm = (code) => {
    setShowAddUserModal(false); // Cerrando el modal del formulario
    setShowSuccessModal(true); // Mostrando el modal de éxito
    setGeneratedCode(code);
    updateTable(); // Llama a la función de actualización
  };

  return (
    <div className="container-employees">
      <p className="title">Empleados</p>

      <div className="container-inputs-employees">
        <div className="container-search-employees">
          <Search placeholder="Buscar un empleado" onChange={handleSearch} />
        </div>
        <AddEmployee openAddUserModal={openAddUserModal} />
      </div>
      <TableEmployees
        searchUser={searchUser}
        filterType={filterType}
        updateTable={updateTable}
      />

      {showAddUserModal && (
        <div className="overlay">
          <NewUser
            closeForm={closeAddUserModal}
            handleSubmitForm={handleSubmitForm}
          />
        </div>
      )}

      {showSuccessModal && (
        <div className="overlay">
          <CodeSuccess
            closeSuccessModal={closeSuccessModal}
            generatedCode={generatedCode}
            updateTable={updateTable}
          />
        </div>
      )}
    </div>
  );
}
