import React from "react";

import { IoSearchOutline } from "react-icons/io5";

// Styles
import "./search.css";

export default function Search({ placeholder, onChange }) {
  return (
    <div className="container-search">
      <input
        type="text"
        className="input-search"
        placeholder={placeholder}
        onChange={onChange}
      />
      <IoSearchOutline className="search-icon" />
    </div>
  );
}
