import React from "react";

// Components
import FormSupport from "../../moleculas/formSupport/formSupport";

// Icons
import { FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";

// Styles
import "./contactSupport.css";

export default function ContactSupport() {
  return (
    <div className="container-contact-support">
      <div className="contact-side">
        <div>
          <h1 className="title-contact-support">
            Formulario de contacto para asistencia con el código de acceso
          </h1>

          <p className="text-contact-support">
            Estamos aquí para ayudarte a resolver cualquier problema que puedas
            tener para iniciar sesión en el sistema.
          </p>
        </div>

        <div className="container-contact">
          <p className="subtitle-contact">Información de contacto</p>
          <div className="contact-options">
            <FaPhone className="contact-icon" />
            <p className="text-contact-support">+52 55 6566 7506</p>
          </div>
          <div className="contact-options">
            <IoMdMail className="contact-icon" />
            <p className="text-contact-support">hello@icarotechnologies.tech</p>
          </div>
          <div className="contact-options">
            <IoLocationSharp className="contact-icon" />
            <p className="text-contact-support">
              Querétaro. El Marqués, 76269 Santiago de Querétaro
            </p>
          </div>
        </div>
      </div>

      <div className="contact-side-form">
        <FormSupport />
      </div>
    </div>
  );
}
