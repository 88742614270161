import React from "react";
import { useNavigate } from "react-router-dom";

import "./editClient.css";
import Profile from "../../../assets/Profile.png";

export default function EditClient() {
  const navigate = useNavigate();

  return (
    <>
      <div className="form-profile">
        <div className="form-side-img">
          <img
            src={Profile}
            style={{ width: "160px", marginBottom: "18px", marginTop: "42px" }}
          />
          <div class="custom-file-upload">
            <label for="file-upload" class="btn-upload-img">
              Editar foto de perfil
            </label>
            <input id="file-upload" type="file" className="input-file" />
          </div>
        </div>

        <div className="form-side-info">
          <h1 className="form-title">Tus datos</h1>
          <p>Nombre de la empresa</p>
          <input
            type="text"
            placeholder="ICARO Transportes"
            className="input-info"
            disabled
          />

          <p>Correo de la persona encargada</p>
          <input
            type="text"
            placeholder="email@icaro.com"
            className="input-info"
            disabled
          />

          <div className="container-phone">
            <div style={{ width: "50%" }}>
              <p>Número telefónico</p>
              <input
                type="text"
                placeholder="222 222 2222"
                className="input-phone"
                disabled
              />
            </div>

            <div>
              <p>Extención (opcional)</p>
              <input
                type="text"
                placeholder=""
                className="input-phone"
                disabled
              />
            </div>
          </div>

          <p>Giro (opcional)</p>
          <input
            type="text"
            placeholder="Capacitación y Desarrollo"
            className="input-info"
            disabled
          />
          <button
            type="submit"
            className="button-update-info"
            onClick={() => navigate("/dashboard/home")}
          >
            Guardar información
          </button>
        </div>
      </div>
    </>
  );
}
