import React, { useEffect, useState } from "react";

import { doc, updateDoc } from "firebase/firestore";
import { dbDashboard } from "../../../services/firebase";

// Styles
import "./editUser.css";

export default function EditUser({ closeEditUserForm, user, updateUsers }) {
  const [editedUser, setEditedUser] = useState({});

  useEffect(() => {
    if (user) {
      setEditedUser({
        name: user.name || "",
        rol: user.rol || "",
        department: user.department || "",
        training_status: user.training_status || "",
      });
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userDocRef = doc(dbDashboard, "users", user.id);

      // Realizar la actualización en Firestore con los nuevos datos del usuario
      await updateDoc(userDocRef, {
        rol: editedUser.rol,
        department: editedUser.department,
        training_status: editedUser.training_status,
      });

      updateUsers();

      // Una vez actualizado con éxito, cerrar el formulario
      closeEditUserForm();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-new-user">
        <div className="header-form">
          <p>Editar usuario</p>
          <span className="btn-close" onClick={closeEditUserForm}></span>
        </div>

        <div className="container-input">
          <label>Nombre completo</label>
          <input
            type="text"
            className="input-form"
            placeholder={user.name}
            disabled
          />
        </div>

        <div className="input-form-group">
          <div className="input-position">
            <label>Cargo</label>
            <select
              className="input-form"
              name="rol"
              value={editedUser.rol}
              onChange={handleInputChange}
            >
              <option value={`${user.rol}`}>{user.rol}</option>
              <option value="Frontend">Frontend</option>
              <option value="Backend">Backend</option>
              <option value="Fullstack">Fullstack</option>
            </select>
          </div>

          <div className="input-department">
            <label>Departamento</label>
            <select
              className="input-form"
              name="department"
              value={editedUser.department}
              onChange={handleInputChange}
            >
              <option value={`${user.department}`}>{user.department}</option>
              <option value="Desarrollo">Desarrollo</option>
              <option value="Ventas">Ventas</option>
              <option value="Marketing">Marketing</option>
            </select>
          </div>

          <div className="input-training-status">
            <label>Estado</label>
            <select
              className="input-form"
              name="training_status"
              value={editedUser.training_status}
              onChange={handleInputChange}
            >
              <option value={`${user.training_status}`}>
                {user.training_status}
              </option>
              <option value="Finalizado">Finalizado</option>
              <option value="En pausa">En pausa</option>
              <option value="En curso">En curso</option>
            </select>
          </div>
        </div>

        <button type="submit" className="button-form">
          Editar usuario
        </button>
      </form>
    </>
  );
}
