import React from "react";
import { useNavigate } from "react-router-dom";

// Icons
import { FaCircle } from "react-icons/fa";

// Styles
import "./cardTraining.css";

export default function CardTraining({ image, title, subtitle, date, status }) {
  const navigate = useNavigate();

  return (
    <div className="card" onClick={() => navigate("/dashboard/empleados")}>
      <div className="card-img">
        <img src={image} alt="img" />
      </div>
      <p className="card-title">{title}</p>
      <p className="card-subtitle">{subtitle}</p>
      <p className="card-subtitle">{date}</p>
      <div className="card-status">
        <FaCircle />
        <span className="status-text">{status}</span>
      </div>
    </div>
  );
}
