import React from "react";

import "./infoCode.css";

export default function InfoCode({ closeInfoCodeModal, user }) {
  return (
    <div className="modal-info-code">
      <div className="header-modal">
        <p>Código de acceso del usuario</p>
        <span className="btn-close" onClick={closeInfoCodeModal}></span>
      </div>

      <p className="modal-title">Código de acceso de {user.name}</p>
      <span className="modal-subtitle">
        Utiliza este código al iniciar sesión para acceder a tu cuenta
      </span>
      <div className="container-code">
        <p className="code">{user.code}</p>
      </div>
      <button className="btn-success" onClick={closeInfoCodeModal}>
        Cerrar
      </button>
    </div>
  );
}
