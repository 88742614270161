import React, { useEffect, useState } from "react";

// Firebase config
import { dbDashboard, getUrl } from "../../../services/firebase";
import { collection, getDocs } from "firebase/firestore";

// PDF
import jsPDF from "jspdf";

// Icons
import { RiDeleteBin6Line } from "react-icons/ri";

// Components
import Modal from "react-modal";
import VideoPlayer from "../../atomos/videoPlayer/videoPlayer";
import DeleteTraining from "../../moleculas/deleteTraining/deleteTraining";

import pdf from "../../../controllers/pdf";

// Styles
import "./tableEmployeeDetails.css";

Modal.setAppElement("#root");

export default function TableEmployeeDetails({ training, user }) {
  const [trainings, setTrainings] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);

  // Controla el modal de eliminación de usuario
  const [showDeleteTraining, setShowDeleteTraining] = useState(false);
  const [selectedTrainingForDeletion, setSelectedTrainingForDeletion] =
    useState(null);

  // Referenciamos a la dbDashboard
  const trainingCollection = collection(dbDashboard, "trainingInformation");

  useEffect(() => {
    async function fetchUrls() {
      try {
        const urls = await Promise.all(
          training.map((info) => getUrl(info.video))
        );
        setVideoUrls(urls);
      } catch (error) {
        console.error("Error al obtener las URLs de los videos", error);
      }
    }

    fetchUrls();
  }, [training]);

  const updateTraining = async () => {
    try {
      const dataTraining = await getDocs(trainingCollection);
      setTrainings(
        dataTraining.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error("Error updating trainings: ", error);
    }
  };

  const openModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openDeleteTrainingModal = (info) => {
    setSelectedTrainingForDeletion(info);
    setShowDeleteTraining(true);
  };

  const closeDeleteTrainingModal = () => {
    setShowDeleteTraining(false);
  };

  const userInfo = user;

  const generatePdf = (trainingInfo) => {
    pdf(trainingInfo, userInfo);
  };

  return (
    <>
      <table className="table-dashboard">
        <thead className="table-title">
          <tr>
            <th>Nombre de la capacitación</th>
            <th>Fecha de inicio</th>
            <th>Resumen de la capacitación</th>
            <th>Video de la capacitación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {training.map((info, index) => (
            <tr key={info.id} className="table-item">
              <td>{info.name_of_training}</td>
              <td>{info.start_date}</td>
              <td>
                <a
                  className="table-video-link"
                  onClick={() => generatePdf(info)}
                >
                  Descargar análisis
                </a>
              </td>
              <td>
                {videoUrls[index] && (
                  <a
                    className="table-video-link"
                    onClick={() => openModal(videoUrls[index])}
                  >
                    Ver video
                  </a>
                )}
              </td>
              <td className="container-icons">
                <RiDeleteBin6Line
                  className="icon-table"
                  onClick={() => openDeleteTrainingModal(info)}
                />
              </td>
            </tr>
          ))}
        </tbody>
        {showDeleteTraining && (
          <div className="overlay">
            <DeleteTraining
              closeDeleteTrainingModal={closeDeleteTrainingModal}
              info={selectedTrainingForDeletion}
              updateTraining={updateTraining}
            />
          </div>
        )}
      </table>

      <VideoPlayer
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        videoUrl={selectedVideoUrl}
      />
    </>
  );
}
