import React from "react";
import NavBar from "../components/organismos/navBar/navBar";
import NavBarMobile from "../components/organismos/navBarMobile/navBarMobile";
import ContactSupport from "../components/organismos/contactSupport/contactSupport";
import Footer from "../components/organismos/footer/footer";

export const ContactSupportPage = () => {
  return (
    <div className="background-icaro">
      <NavBar />
      <NavBarMobile />
      <ContactSupport />
      <Footer />
    </div>
  );
};
