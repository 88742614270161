import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDKTqK5Bd4algVtudg9Ix1UeEs7eGfJhKs",
  authDomain: "icarotech-b2fce.firebaseapp.com",
  projectId: "icarotech-b2fce",
  storageBucket: "icarotech-b2fce.appspot.com",
  messagingSenderId: "587862125043",
  appId: "1:587862125043:web:17bfbb5ad2d2f348c94a86",
};

const firebaseConfigDashboard = {
  apiKey: "AIzaSyB7I8w-LJUjLE1c2CVOUDiJYdxUytefLOg",
  authDomain: "icaro-dashboard.firebaseapp.com",
  projectId: "icaro-dashboard",
  storageBucket: "icaro-dashboard.appspot.com",
  messagingSenderId: "897450327054",
  appId: "1:897450327054:web:79bd6c51cd607eec75d05f",
};

// Inicializar Firebase para el primer proyecto
const app = initializeApp(firebaseConfig, "project1");
export const db = getFirestore(app);

// Inicializar Firebase para el segundo proyecto
const appDashboard = initializeApp(firebaseConfigDashboard, "project2");
export const auth = getAuth(appDashboard);

// Conexion a la base de datos para el segundo proyecto
export const dbDashboard = getFirestore(appDashboard);

// Conecion al storage
export const storage = getStorage(appDashboard);

// Obtener una referencia al storage para el video
export async function getUrl(videoFile) {
  const videoRef = ref(storage, `capacitaciones/${videoFile}.mp4`);
  const url = await getDownloadURL(videoRef);
  return url;
}
