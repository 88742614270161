import React from "react";
import Modal from "react-modal";

// Styles
import "./videoPlayer.css";

Modal.setAppElement("#root");

export default function VideoPlayer({ modalIsOpen, closeModal, videoUrl }) {
  return (
    <Modal
      className="modal-video"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Reproductor de Video"
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      }}
    >
      <p onClick={closeModal}>x</p>
      {videoUrl && (
        <>
          <video controls width="100%">
            <source src={videoUrl} type="video/mp4" />
            Tu navegador no soporta el reproductor de video.
          </video>
        </>
      )}
    </Modal>
  );
}
