import React, { useState } from "react";

// Components
import Search from "../../atomos/search/search";
import CardTraining from "../../moleculas/cardTraining/cardTraining";

// Images
import ImageCart from "../../../assets/imgs/image-cart.png";
import ImageMaintenance from "../../../assets/imgs/image-maintenance.png";
import ImageManufacturing from "../../../assets/imgs/image-manufacturing.png";

// Styles
import "./homeDashboard.css";

export const HomeDashboard = () => {
  const [searchText, setSearchText] = useState("");
  const trainings = [
    {
      image: ImageCart,
      title: "Capacitación de manejo con Realidad Virtual",
      subtitle: "3,000 empleados capacitados",
      date: "01/12/2023 - 01/12/2024",
      status: "Disponible",
    },
    {
      image: ImageMaintenance,
      title: "Capacitación de Prevención de riesgos en planta",
      subtitle: "5,000 empleados capacitados",
      date: "01/12/2023 - 01/12/2024",
      status: "Disponible",
    },
    {
      image: ImageManufacturing,
      title: "Capacitación de Banda transportadora",
      subtitle: "1,000 empleados capacitados",
      date: "01/12/2023 - 01/12/2024",
      status: "Disponible",
    },
  ];

  // Función para manejar cambios en el campo de búsqueda
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  // Filtrar las capacitaciones basadas en el texto de búsqueda
  const filteredTrainings = trainings.filter((training) =>
    training.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="container-home">
      <p className="title">Capacitaciones</p>

      <Search
        placeholder={"Buscar una capacitación"}
        onChange={handleSearchChange}
      />

      <div className="container-cards">
        {filteredTrainings.map((training, index) => (
          <CardTraining
            key={index}
            image={training.image}
            title={training.title}
            subtitle={training.subtitle}
            date={training.date}
            status={training.status}
          />
        ))}
      </div>
    </div>
  );
};
