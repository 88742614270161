import React from "react";
import { NavLink } from "react-router-dom";

// Icons
import { BiSolidUser } from "react-icons/bi";
import { MdSpaceDashboard } from "react-icons/md";
import { TbInfoSquareRoundedFilled } from "react-icons/tb";

// Image
import Logo from "../../../assets/iconos/Logo.svg";

// Styles
import "./sidebar.css";

export default function Sidebar() {
  return (
    <div className="sidebar">
      <img src={Logo} alt="Logo" className="sidebar-logo" />
      <ul>
        <li className="sidebar-subtitle">MENU</li>
        <li className="sidebar-item">
          <NavLink to="home" exact="true" className="link-item">
            <MdSpaceDashboard className="sidebar-icon" />
            Dashboard
          </NavLink>
        </li>
        <li className="sidebar-item">
          <NavLink to="empleados" exact="true" className="link-item">
            <BiSolidUser className="sidebar-icon" />
            Empleados
          </NavLink>
        </li>
        <li className="sidebar-subtitle">OTROS</li>
        <li className="sidebar-item">
          <NavLink to="ayuda" exact="true" className="link-item">
            <TbInfoSquareRoundedFilled className="sidebar-icon" />
            Ayuda
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
