import React from "react";

// Components
import CardQuestion from "../../moleculas/cardQuestion/cardQuestion";

// Styles
import "./faq.css";

export default function Faq() {
  return (
    <div className="container-faq">
      <p className="title-faq">Preguntas Frecuentes</p>

      <CardQuestion />
      <CardQuestion />
    </div>
  );
}
