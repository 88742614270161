import React from "react";
import NavBar from "../components/organismos/navBar/navBar";
import NavBarMobile from "../components/organismos/navBarMobile/navBarMobile";
import Footer from "../components/organismos/footer/footer";
import Header from "../components/organismos/header/header";
import PorqueElegirnos from "../components/organismos/porqueElegirnos/porqueElegirnos";
import SoftwareEscalar from "../components/organismos/softwareEscalar/softwareEscalar";
import Transformamos from "../components/organismos/trasnformamos/transformamos";

export const HomePage = () => {
  return (
    <div className="background-icaro">
      <NavBar />
      <NavBarMobile />
      <Header />
      <PorqueElegirnos />
      <SoftwareEscalar />
      <Transformamos />
      <Footer />
    </div>
  );
};
