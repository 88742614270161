import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

// Icon
import { BsCheck2Circle } from "react-icons/bs";

import "./formSupport.css";

export default function FormSupport() {
  const form = useRef();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const sendEmail = (event) => {
    event.preventDefault();

    // Configuración de emailjs
    const serviceId = "service_dbmjbub";
    const templateId = "template_2luhb0o";
    const apiKey = "lVfg7KCmL0PU04XzH";

    // Función para enviar el correo
    emailjs.sendForm(serviceId, templateId, form.current, apiKey).then(
      (result) => {
        console.log(result.text);
        setShowConfirmationModal(true);
      },
      (error) => {
        console.error(error.text);
      }
    );
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <div className="container-form-support">
        <form
          ref={form}
          action=""
          onSubmit={sendEmail}
          className="form-support"
        >
          <div className="container-input-contact">
            <span className="input-text">Nombre completo</span>
            <input
              type="text"
              placeholder="Tu nombre"
              className="input-support"
              name="user_name"
              required
            />
          </div>
          <div className="container-input-contact">
            <span className="input-text">Correo electrónico</span>
            <input
              type="email"
              placeholder="correo@email.com"
              className="input-support"
              name="user_email"
              required
            />
          </div>
          <div className="container-input-contact">
            <span className="input-text">Teléfono de contacto</span>
            <input
              type="tel"
              placeholder="222-222-2222"
              className="input-support"
              name="user_phone_number"
              required
            />
          </div>
          <div className="container-input-contact">
            <span className="input-text">Mensaje</span>
            <textarea
              type="text"
              placeholder="¿En qué podemos ayudarte?"
              className="input-support-large"
              name="message"
              required
            />
          </div>
          <button type="submit" className="button-send-msg">
            Enviar mensaje
          </button>
        </form>
      </div>

      {/* Modal de confirmación */}
      {showConfirmationModal && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <BsCheck2Circle className="check-icon-modal" />
            <p>¡El mensaje se envió con éxito!</p>
            <button onClick={closeModal}>Cerrar</button>
          </div>
        </div>
      )}
    </>
  );
}
