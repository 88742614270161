import React from "react";

import EditClient from "../components/organismos/editClient/editClient";

export default function EditClientPage() {
  return (
    <div>
      <EditClient />
    </div>
  );
}
