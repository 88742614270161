import "jspdf-autotable";
import jsPDF from "jspdf";

const pdf = (trainingInfo, userInfo) => {
  console.log(trainingInfo);
  console.log(userInfo);

  const doc = new jsPDF();

  // Establece el tamaño de la tipografía
  const fontSize = 12; // ajusta según tu preferencia
  doc.setFontSize(fontSize);

  // Agrega la imagen
  const imageUrl = "/logoDoc.jpeg"; // Reemplaza con la URL de tu imagen
  doc.addImage(imageUrl, "JPEG", 14, 20, 38, 30); // Ajusta las coordenadas y dimensiones según tu diseño

  // Establece el tamaño de fuente y el estilo en negrita
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");

  // Agrega el título
  doc.text(
    `Nombre de la capacitación: ${trainingInfo.name_of_training}`,
    14,
    76
  );

  // Restaura la configuración de la fuente a la normal
  doc.setFontSize(fontSize);
  doc.setFont("helvetica", "normal");

  // Agrega la fecha a la derecha
  doc.text(`Fecha: ${trainingInfo.start_date}`, 160, 56);

  // Si se necesita que se imprima con la fecha actual
  // const currentDate = new Date().toLocaleDateString();
  // doc.text(`Fecha: ${currentDate}`, 140, 80);

  doc.text(`Nombre: ${userInfo.name}`, 14, 84);
  doc.text(`Puesto: ${userInfo.department}`, 14, 92);
  doc.text(`Cargo: ${userInfo.rol}`, 14, 100);

  doc.text(`Primer Maquinado:`, 14, 116);

  // Agrega la primera tabla (3x5)
  const firstTableData = [
    ["Tarea", "Número de aciertos", "Número de errores"],
    ["Uso del freno para el husillo", "(variable)", "(variable)"],
    ["Velocidad de las poleas", "(variable)", "(variable)"],
    ["Profundidad de corte", "(variable)", "(variable)"],
    ["Velocidad de corte", "(variable)", "(variable)"],
  ];
  doc.autoTable({
    startY: 121, // Ajusta la posición vertical según tu diseño
    head: [firstTableData[0]],
    body: firstTableData.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  // Agrega la segunda tabla (2x2)
  const secondTableData = [
    ["Puntuación Máxima", "Puntuación obtenida"],
    ["900", "(variable)"],
  ];
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 10, // Ajusta la posición vertical según tu diseño
    head: [secondTableData[0]],
    body: secondTableData.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  // Agrega la tercera tabla (2x2)
  const thirdTableData = [
    ["Tiempo óptimo", "Tiempo obtenido"],
    ["30 minutos", "(variable)"],
  ];
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 10, // Ajusta la posición vertical según tu diseño
    head: [thirdTableData[0]],
    body: thirdTableData.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  doc.text(`Segundo Maquinado:`, 14, 225);

  // Agrega la segunda tabla (3x5)
  const firstTableData2 = [
    ["Tarea", "Número de aciertos", "Número de errores"],
    ["Uso del freno para el husillo", "(variable)", "(variable)"],
    ["Velocidad de las poleas", "(variable)", "(variable)"],
    ["Profundidad de corte", "(variable)", "(variable)"],
    ["Velocidad de corte", "(variable)", "(variable)"],
  ];
  doc.autoTable({
    startY: 230, // Ajusta la posición vertical según tu diseño
    head: [firstTableData2[0]],
    body: firstTableData2.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  // Agrega la segunda tabla (2x2)
  const secondTableData2 = [
    ["Puntuación Máxima", "Puntuación obtenida"],
    ["900", "(variable)"],
  ];
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 10, // Ajusta la posición vertical según tu diseño
    head: [secondTableData2[0]],
    body: secondTableData2.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  // Agrega la tercera tabla (2x2)
  const thirdTableData2 = [
    ["Tiempo óptimo", "Tiempo obtenido"],
    ["30 minutos", "(variable)"],
  ];
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 10, // Ajusta la posición vertical según tu diseño
    head: [thirdTableData2[0]],
    body: thirdTableData2.slice(1),
    theme: "grid",
    headStyles: {
      fillColor: [46, 27, 108],
    },
  });

  // Guarda el PDF con un nombre específico
  doc.save(`Reporte de desempeño.pdf`);
};

export default pdf;
