import React, { useState } from "react";

// Styles
import "./cardQuestion.css";

export default function CardQuestion() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      // className={`conteiner-card-question ${isExpanded ? "expanded" : ""}`}
      className="conteiner-card-question"
      onClick={handleExpand}
    >
      <div className={`card-question ${isExpanded ? "expanded" : ""}`}>
        <div className="number-question">01</div>
        <div className="title-question">
          <p>Lorem ipsum dolor sit amet consectetur. Viverra.</p>
        </div>
        <div className="btn-expand">+</div>
      </div>
      {isExpanded && (
        <div className="card-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            viverra libero ut mi egestas fermentum. Nam eget nunc non nisi
            pharetra ultrices eget id diam. Vivamus risus nisl, ultricies eget
            sagittis id, hendrerit sit amet ante. Pellentesque habitant morbi
            tristique senectus et netus et malesuada fames ac turpis egestas.
            Cras in lorem quam. Fusce id condimentum risus. Aenean malesuada
            suscipit sem, bibendum elementum velit consequat a. Morbi iaculis
            vestibulum nunc vel suscipit. In lobortis arcu purus, et tincidunt
            nulla viverra quis. Pellentesque vitae velit rutrum, vehicula ante
            a, tincidunt enim. Proin porta hendrerit tempor. Nulla et libero vel
            sapien consectetur faucibus. Duis ac porta mi. Donec pharetra
            laoreet mattis. Donec auctor augue molestie tincidunt ornare.
          </p>
        </div>
      )}
    </div>
  );
}
