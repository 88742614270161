import React, { useState } from "react";
import { useAuth } from "../../../context";
import { useNavigate } from "react-router-dom";

// Images
import ProfileImg from "../../../assets/Profile.png";

// Icons
import { IoIosArrowDown } from "react-icons/io";

// Styles
import "./profile.css";

export default function Profile() {
  const { user, logout } = useAuth();
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className="container-profile" onClick={toggleMenu}>
      <img src={ProfileImg} alt="Profile" className="img-profile" />
      <p className="name-profile">{user.email}</p>
      <IoIosArrowDown />

      {showMenu && (
        <div className="dropdown-menu-dashboard">
          <ul>
            <li onClick={() => navigate("/dashboard/editar-usuario")}>
              Ver mi perfil
            </li>
            <li onClick={() => handleLogout()}>Cerrar sesión</li>
          </ul>
        </div>
      )}
    </div>
  );
}
